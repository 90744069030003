import {
  Button,
  Center,
  Flex,
  Group,
  Loader,
  LoadingOverlay,
  NumberInput,
  SegmentedControl,
  Stack,
  Switch,
  Text,
  Textarea,
  TextInput,
  Tooltip,
} from "@mantine/core";
import TextEditor from "../../../../components/ExternalEditors/TextEditor";
import { lazy, useEffect, useMemo, useRef, useState } from "react";
import { useConfigStore } from "../../../../stores/ConfigStore";
import { getFields } from "../../../../api/ContactAPI";
import AddTokens from "../../../../components/Adder/AddTokens";
import { useLanguage } from "../../../../stores/LanguageStore";
import {
  IconBaselineDensityMedium,
  IconPlus,
  IconTemplate,
  IconTextSize,
} from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import { createMEmail, getMEmails } from "../../../../api/ModelsApi";
import { notifications } from "@mantine/notifications";
import EmailModalPreview from "../../../../components/View/EmailModalPreview";
import Generator from "./Generator";
import TemplateEditor from "../../../../components/ExternalEditors/TemplateEditor";
import BackButton from "../../../../components/View/BackButton";
import useNavigationBlocker from "../../../../components/View/NavigationBlocker";
import { useRouteLoaderData } from "react-router-dom";
import FolderSelector from "../../../../components/Selectors/FolderSelector";
import { useUIStore } from "../../../../stores/UIStore";
import Spintax from "./Spintax";
import HtmlImporter from "./HtmlImporter";
import { getDefaultHtmlCode } from "../modelUtils";
import FilesModal from "../../files/FilesModal";
import SendBAT from "./SendBAT";
import { wrapDatafieldValue } from "../../../../functions/utilFunctions";
import GlobalLanguageSelector from "../../../../components/Selectors/GlobalLanguageSelector";
import TemperatureSlider from "../../../../components/View/TemperatureSlider";
const HtmlEditor = lazy(() =>
  import("../../../../components/ExternalEditors/HtmlEditor")
);

const CreateModelEmail = ({
  handleClose,
  defaultCreationType,
  onCreated,
  inModal,
  is_ai,
}) => {
  const lang = useLanguage((s) => s.language);
  const editorRef = useRef();
  const templateEditorRef = useRef();
  const codeRef = useRef();

  const [templateData, setTemplateData] = useState(null);
  const [htmlData, setHtmlData] = useState(null);

  const folderProfile = useRouteLoaderData("model-folder");
  const [folder, setFolder] = useState(folderProfile || null);
  const datafields = useConfigStore((s) => s.datafields);
  const [loading, setLoading] = useState(true);
  const [dirty, setDirty] = useState(false);

  const datafieldTokens = useMemo(() => {
    let df = datafields.map((x) => ({
      label: x.name,
      value: x.identifier_placeholder,
    }));

    df = [
      {
        label: lang.components.variable_manager.dialog.signature_variable_name,
        value: "%signature%",
        name: lang.components.variable_manager.dialog.signature_variable_name,
      },
      {
        label:
          lang.components.variable_manager.dialog.unsubscribe_variable_name,
        value: "%unsubscribe%",
        name: lang.components.variable_manager.dialog.unsubscribe_variable_name,
      },
      {
        label: lang.components.variable_manager.dialog.webversion_variable_name,
        value: "%webversion%",
        name: lang.components.variable_manager.dialog.webversion_variable_name,
      },
      {
        label: lang.components.variable_manager.dialog.b64_variable_name,
        value: "%email_b64%",
        name: lang.components.variable_manager.dialog.b64_variable_name,
      },
      ...df,
    ];

    return df;
  }, [datafields, lang]);

  const datafieldTokensTemplate = useMemo(() => {
    let mergeTags = {
      [`[ ${lang.components.variable_manager.dialog.signature_variable_name} ]`]:
        {
          name: lang.components.variable_manager.dialog.signature_variable_name,
          value: "%signature%",
          sample: `[ ${lang.components.variable_manager.dialog.signature_variable_name} ]`,
        },
      [`[ ${lang.components.variable_manager.dialog.unsubscribe_variable_name} ]`]:
        {
          name: lang.components.variable_manager.dialog
            .unsubscribe_variable_name,
          value: "%unsubscribe%",
          sample: `[ ${lang.components.variable_manager.dialog.unsubscribe_variable_name} ]`,
        },
      [`[ ${lang.components.variable_manager.dialog.webversion_variable_name} ]`]:
        {
          name: lang.components.variable_manager.dialog
            .webversion_variable_name,
          value: "%webversion%",
          sample: `[ ${lang.components.variable_manager.dialog.webversion_variable_name} ]`,
        },
      [`[ ${lang.components.variable_manager.dialog.b64_variable_name} ]`]: {
        name: lang.components.variable_manager.dialog.b64_variable_name,
        value: "%email_b64%",
        sample: `[ ${lang.components.variable_manager.dialog.b64_variable_name} ]`,
      },
    };
    datafields.forEach((x) => {
      mergeTags = {
        ...mergeTags,
        [`[ ${x.name.toUpperCase()} ]`]: {
          name: x.name,
          value: x.identifier_placeholder,
          sample: `[ ${x.name.toUpperCase()} ]`,
        },
      };
    });
    return mergeTags;
  }, [datafields, lang]);

  useEffect(() => {
    getFields().finally(() => {
      setLoading(false);
    });
  }, []);

  //#region Creation Type
  const [active, setActive] = useState(defaultCreationType || "text");
  const [shouldWarn, setShouldWarn] = useState(false);

  const onActiveChange = (v) => {
    async function handleChange() {
      let html = "";
      let design_json = "";

      if (active === "text") {
        html = editorRef?.current?.getContent() || code;

        setHtmlData(html);
      } else {
        design_json = await new Promise((resolve) =>
          templateEditorRef.current.saveDesign((design) => {
            resolve(JSON.stringify(design));
          })
        );
        setTemplateData(design_json);
      }

      setShouldWarn(false);
      setActive(v);
    }

    if (shouldWarn)
      modals.openConfirmModal({
        title: lang.models.alert_editor.title,
        children: <Text size="sm">{lang.models.alert_editor.text}</Text>,
        labels: { confirm: lang.global.confirm, cancel: lang.global.cancel },
        onCancel: () => {},
        onConfirm: handleChange,
        centered: true,
      });
    else handleChange();
  };

  const handleWarn = () => {
    setShouldWarn?.(true);
    setDirty(true);
  };
  //#endregion

  const handlePreview = ({ setHtml, setSubject, open }) => {
    const fd = new FormData(document.getElementById("model-form"));
    const data = Object.fromEntries(fd);

    if (active === "text") {
      setHtml(code || editorRef.current.getContent());
      setSubject(data.subject);
      open();
    } else {
      templateEditorRef.current.exportHtml((ex) => {
        setHtml(ex.html);
        setSubject(data.subject);
        open();
      });
    }
  };

  const [code, setCode] = useState(null);

  const handleHtmlImport = (html) => {
    setCode(html);
  };

  const [submitting, setSubmitting] = useState(false);
  const onSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const fd = new FormData(e.target);
    const data = Object.fromEntries(fd);

    let html = "";
    let text = "";
    let design_json = "";

    if (active === "text") {
      html = editorRef?.current?.getContent() || code;
      text = editorRef?.current?.getContent({ format: "text" }) || "text";
    } else {
      html = await new Promise((resolve) =>
        templateEditorRef.current.exportHtml((ex) => {
          resolve(ex.html);
        })
      );
      text = await new Promise((resolve) =>
        templateEditorRef.current.exportPlainText((ex) => {
          resolve(ex.text);
        })
      );
      design_json = await new Promise((resolve) =>
        templateEditorRef.current.saveDesign((design) => {
          resolve(JSON.stringify(design));
        })
      );
    }

    return createMEmail({
      name: data.name,
      html: html || htmlData,
      text,
      subject: data.subject,
      is_template: active === "template",
      template: design_json || templateData,
      folder_id: folder?.id,

      ai_number_paragraphs: data?.ai_number_paragraphs
        ? parseInt(data?.ai_number_paragraphs)
        : null,
      ai_language: data?.ai_language,
      ai_prompt: data?.ai_prompt,
      ai_temperature: parseFloat(data?.temperature),
      is_ai,
    })
      .then((response) => {
        let newId = response.data.model_id;
        setSubmitting(true);
        setDirty(false);
        setShouldWarn(false);
        getMEmails()
          .then((res) => {
            let newItemsList = res.data.models_list;
            let newItem = newItemsList.find((x) => x.id === newId);
            notifications.show({
              title: lang.models.created,
              message: lang.models.created_text,
              color: "tertiary.8",
            });
            onCreated?.(newItem);
            handleClose?.();
          })
          .finally(() => {
            setSubmitting(false);
          });
        if (folderProfile) {
          useUIStore.getState().setAsideLoading(true);
        }
      })
      .catch(() => setSubmitting(false));
  };

  const onEmailGenerated = (html) => {
    if (shouldWarn) {
      return modals.openConfirmModal({
        title: lang.models.generator.confirm.title,
        children: <Text size="sm">{lang.models.generator.confirm.text}</Text>,
        labels: {
          confirm: lang.models.generator.confirm.replace,
          cancel: lang.global.cancel,
        },
        onCancel: () => {},
        onConfirm: () => {
          if (code !== null) {
            handleHtmlImport(html);
          } else editorRef.current?.setContent(html);
        },
        centered: true,
      });
    }

    if (code !== null) {
      handleHtmlImport(html);
    } else editorRef.current?.setContent(html);
  };

  useNavigationBlocker(dirty || shouldWarn, "form-submitter");

  return (
    <>
      <LoadingOverlay
        visible={submitting}
        overlayProps={{ blur: 1 }}
        zIndex={1001}
      />

      <Stack
        component={"form"}
        onSubmit={onSubmit}
        gap={0}
        id="model-form"
        h={inModal ? "78vh" : "100%"}
        w={"100%"}
        onChange={() => setDirty(true)}
        flex={1}
      >
        <Group wrap="nowrap">
          <TextInput
            label={lang.model_emails.create.dialog.name}
            placeholder={lang.model_emails.create.dialog.name}
            description={is_ai ? lang.quick_campaign.name_desc : null}
            name="name"
            w={is_ai ? "100%" : "60%"}
            required
            data-autofocus
            autoFocus
          />
          <TextInput
            id="email-subject"
            label={lang.model_emails.create.dialog.subject}
            placeholder={lang.model_emails.create.dialog.subject}
            description={is_ai ? lang.quick_campaign.subject_desc : null}
            name="subject"
            w="100%"
            required={!is_ai}
            styles={{
              input: {
                paddingLeft: 42,
              },
            }}
            leftSection={
              loading ? (
                <Loader type="dots" size={16} />
              ) : (
                <AddTokens
                  items={datafieldTokens}
                  handleAdd={(item) => {
                    let input = document.getElementById("email-subject");
                    input.value += `{${item?.value.replaceAll("%", "")}}`;
                    input.focus();
                  }}
                />
              )
            }
          />
          {folderProfile && (
            <Group w={400}>
              <FolderSelector
                value={folder}
                setValue={setFolder}
                type={"Model"}
              />
            </Group>
          )}
        </Group>

        {is_ai ? (
          <>
            <Textarea
              label={"Prompt"}
              placeholder={lang.quick_campaign.prompt_placeholder}
              description={lang.quick_campaign.prompt_desc}
              name="ai_prompt"
              rows={5}
              w={"100%"}
              maxLength={500}
              mt={12}
              required
            />

            <Group wrap="nowrap" gap={24} mt={12} mb={16}>
              <GlobalLanguageSelector
                name={"ai_language"}
                withinPortal={false}
                size="sm"
                w={"100%"}
                description={lang.quick_campaign.language_desc}
              />

              <TemperatureSlider desc={lang.quick_campaign.temperature_desc} />

              <NumberInput
                name="ai_number_paragraphs"
                label={lang.model_emails.create.dialog.paragraphs}
                description={lang.quick_campaign.paragraph_desc}
                leftSection={
                  <Tooltip label={lang.model_emails.create.dialog.paragraphs}>
                    <IconBaselineDensityMedium size={16} />
                  </Tooltip>
                }
                defaultValue={3}
                w={"100%"}
                size="sm"
                required
                mr={"auto"}
                min={1}
                max={4}
              />
            </Group>
          </>
        ) : (
          <>
            <SegmentedControl
              value={active}
              onChange={onActiveChange}
              data={[
                {
                  value: "text",
                  label: (
                    <Center style={{ gap: 10 }}>
                      <IconTextSize />
                      <span>{lang.models.text_editor}</span>
                    </Center>
                  ),
                },
                {
                  value: "template",
                  label: (
                    <Center style={{ gap: 10 }}>
                      <IconTemplate />
                      <span>{lang.models.template_editor}</span>
                    </Center>
                  ),
                  // disabled: true,
                },
              ]}
              size="sm"
              mt={"sm"}
              style={{
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0,
              }}
              className="segmented34"
            />

            {active === "text" ? (
              <>
                {code !== null ? (
                  <HtmlEditor
                    name={"html-code"}
                    value={code}
                    onChange={setCode}
                    editorRef={codeRef}
                    height={inModal ? 400 : "100%"}
                  >
                    <Group
                      wrap="nowrap"
                      style={{
                        position: "absolute",
                        top: 8,
                        left: 0,
                        zIndex: 4,
                      }}
                      gap={"lg"}
                    >
                      <AddTokens
                        items={datafieldTokens}
                        handleAdd={(item) => {
                          codeRef.current.editor.session.insert(
                            codeRef.current.editor.getCursorPosition(),
                            wrapDatafieldValue(item?.value)
                          );
                        }}
                        long
                      />
                      <Spintax editorRef={codeRef} isCode />
                      <FilesModal editorRef={codeRef} isCode />
                      <Generator onFinish={onEmailGenerated} />
                    </Group>
                    <Group
                      wrap="nowrap"
                      style={{
                        position: "absolute",
                        top: 7,
                        right: 8,
                        zIndex: 4,
                      }}
                      justify="left"
                      gap={"lg"}
                    >
                      <HtmlImporter onFinish={handleHtmlImport} />
                      <Switch
                        label={lang.model_emails.html_editor}
                        checked={code !== null}
                        onClick={() => {
                          setTimeout(() => {
                            editorRef.current.setContent(code, {
                              format: "raw",
                            });
                          }, 1000);
                          setCode(null);
                        }}
                      />
                    </Group>
                  </HtmlEditor>
                ) : (
                  <TextEditor
                    editorRef={editorRef}
                    placeholder={lang.model_emails.create.dialog.placeholder}
                    onKeyUp={handleWarn}
                    tokens={datafieldTokens}
                    onInit={(editor) => {
                      if (htmlData) editor.setContent(htmlData);
                    }}
                  >
                    <Group
                      wrap="nowrap"
                      style={{
                        position: "absolute",
                        top: 8,
                        left: 140,
                        zIndex: 4,
                      }}
                      gap={"lg"}
                    >
                      <Spintax editorRef={editorRef} />
                      <FilesModal editorRef={editorRef} />
                      <Generator onFinish={onEmailGenerated} />
                    </Group>

                    <Group
                      wrap="nowrap"
                      style={{
                        position: "absolute",
                        top: 7,
                        right: 8,
                        zIndex: 4,
                      }}
                      gap={"lg"}
                    >
                      <HtmlImporter onFinish={handleHtmlImport} />
                      <Switch
                        label={lang.model_emails.html_editor}
                        ml={"auto"}
                        checked={!!code}
                        onClick={() => {
                          let actualHtml = editorRef.current.getContent();
                          setCode(getDefaultHtmlCode(actualHtml));
                        }}
                      />
                    </Group>
                  </TextEditor>
                )}
              </>
            ) : (
              <TemplateEditor
                onReady={(editor) => {
                  if (templateData) editor.loadDesign(JSON.parse(templateData));
                  templateEditorRef.current = editor;
                  templateEditorRef.current.addEventListener(
                    "design:updated",
                    function (data) {
                      setShouldWarn?.(true);
                    }
                  );
                  templateEditorRef.current.setMergeTags(
                    datafieldTokensTemplate
                  );
                }}
              />
            )}
          </>
        )}

        <Group justify="space-between" mt={"sm"}>
          {!is_ai && (
            <Flex gap={16}>
              <EmailModalPreview
                getData={handlePreview}
                disableEdit
                disabled={is_ai}
              />
              <SendBAT
                getModel={async () => {
                  let subject =
                    document.getElementById("email-subject")?.value ||
                    "no_subject";
                  let html = "";
                  let text = "";

                  if (active === "text") {
                    html = editorRef?.current?.getContent() || code;
                    text =
                      editorRef?.current?.getContent({ format: "text" }) ||
                      "text";
                  } else {
                    html = await new Promise((resolve) =>
                      templateEditorRef.current.exportHtml((ex) => {
                        resolve(ex.html);
                      })
                    );
                    text = await new Promise((resolve) =>
                      templateEditorRef.current.exportPlainText((ex) => {
                        resolve(ex.text);
                      })
                    );
                  }

                  return {
                    subject,
                    html,
                    text,
                  };
                }}
                disabled={is_ai}
              />
            </Flex>
          )}
          <Flex gap={"md"} ml={"auto"}>
            <BackButton onClick={handleClose} />
            <Button
              type="submit"
              leftSection={<IconPlus size={18} />}
              id="form-submitter"
            >
              {lang.model_emails.create.dialog.submit_button}
            </Button>
          </Flex>
        </Group>
      </Stack>
    </>
  );
};

export default CreateModelEmail;
