import { AppShell, Stack } from "@mantine/core";
import "./LayoutStyles.css";
import { useUIStore } from "../stores/UIStore";
import Main from "./Main/Main";
import { useMemo } from "react";
import Navbar from "./Navbar/Navbar";
import Header from "./Header/Header";

export function MainLayout() {
  const asideCollapsed = useUIStore((s) => s.asideCollapsed);
  const navbarClosed = useUIStore((s) => s.navbarClosed);

  const disableNav = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return (
      localStorage.getItem("disable_nav") === "true" ||
      urlParams.get("disableNav")
    );
  }, []);

  const disableHeading = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return (
      localStorage.getItem("disable_heading") === "true" ||
      urlParams.get("disableHeading")
    );
  }, []);

  return (
    <AppShell
      navbar={{
        width: 200,
        collapsed: {
          desktop: disableNav ? true : asideCollapsed,
          mobile: disableNav ? true : asideCollapsed,
        },
        breakpoint: "sm",
      }}
      header={disableHeading ? null : { height: 60 }}
      padding={0}
      styles={{
        navbar: {
          boxShadow: "none",
        },
      }}
    >
      {!disableHeading && (
        <AppShell.Header style={{ border: 0, zIndex: 120 }}>
          <Header navDisabled={disableNav} />
        </AppShell.Header>
      )}

      <AppShell.Main component={Stack} h={"100%"}>
        <Main />
      </AppShell.Main>

      {!disableNav && (
        <AppShell.Navbar style={{ border: 0 }}>
          <Navbar />
        </AppShell.Navbar>
      )}
    </AppShell>
  );
}
